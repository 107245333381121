import { getPropertyCollaborators, updatePropertyCollaborators } from '@/services/properties'
import { successModal, errorModal, questionModal } from '@/modalMessages'

const schema = [
  {
    type: 'autocomplete',
    autoCompleteType: 'collaborator',
    multiple: true,
    name: 'collaborators',
    label: 'Medewerker',
    placeholder: 'Selecteer medewerkers',
    validation: 'required'
  }
]

const actions = {
  async load (propertyId) {
    try {
      const payload = {
        propertyId,
        params: {
          include_inactive: 1
        }
      }
      const response = await getPropertyCollaborators(payload)
      const collaborators = response.data?.results
      return { collaborators }
    } catch (error) {
      errorModal('Fout bij het laden van medewerkers, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      let ask = true
      const { collaborators } = data
      const collaborator_id_list = collaborators.map(collaborator => {
        if (collaborator.primary) ask = false
        return collaborator.id
      })

      let transfer_matches = false
      if (ask) {
        const result = await questionModal('Wens je ALLE leads voor dit pand over te zetten naar de nieuwe primaire makelaar?')
        if (result.value) transfer_matches = true
      }
      const response = await updatePropertyCollaborators(propertyId, { collaborator_id_list, transfer_matches })
      successModal('Medewerkers zijn succesvol aangepast.')
      return response
    } catch (error) {
      errorModal('Fout bij opslaan van medewerkers, probeer het opnieuw.')
      throw error
    }
  }
}

export default {
  heading: 'Medewerkers',
  schema,
  actions
}
